.App {
  background-color: var(--td-blue-dark);
	display: flex;
	flex-direction: column;
	text-align: center;
	min-height: 100vh;
	line-height: 1.5;
}

.App-logo {
  height: auto;
  pointer-events: none;
  width: 350px;
}

h1 {
	font-weight: 200;
}

.occupation {
	font-weight: 200;
	margin: 0;
	padding: 0 0 2rem;
}

.App-header {
  align-items: center;
  color: white;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  justify-content: center;

  .title {
	margin: 0;
	padding: 0 0 1rem;
  }
}

.App-footer {
	height: .5rem;
	margin-top: auto;
	object-fit: cover;
	padding-top: .5rem;
	width: 100%;
}