body {
  margin: 0;
  font-family: "Martel sans", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
	font-size: 20px;
	--td-blue-dark: #0D0B6F;
	--td-blue-mid: #6070CD;
	--td-blue-light: #C2D2F4;
}